import React from 'react'
import Img from 'gatsby-image'

import { useThemeOptions } from '#hooks'
import { ArrowButton, Layout } from '#components'

export default function NotFound() {
  const {
    notFoundPageButton,
    notFoundPageDescription,
    notFoundPageTitle,
    notFoundPageBackgroundImage,
  } = useThemeOptions()

  return (
    <Layout title="Page Not Found">
      <div className="h-screen relative flex justify-center items-center">
        <div className="absolute inset-0">
          <Img
            alt="Serction Highlight"
            fluid={notFoundPageBackgroundImage.localFile.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            className="w-full h-full absolute inset-0"
          />
        </div>
        <div className="absolute opacity-65 inset-0 bg-black" />
        <div className="container w-full relative text-white pt-60">
          <h1
            className="font-bold text-32 md:text-37 lg:text-46 xl:text-64"
            dangerouslySetInnerHTML={{ __html: notFoundPageTitle }}
          />
          <div
            className="text-26 md:text-32 lg:text-40 xl:text-46 max-w-1090 pb-30 lg:pb-40"
            dangerouslySetInnerHTML={{ __html: notFoundPageDescription }}
          />
          <ArrowButton
            to={notFoundPageButton.url}
            target={notFoundPageButton.target}
            variant="accent"
          >
            {notFoundPageButton.title}
          </ArrowButton>
        </div>
      </div>
    </Layout>
  )
}
